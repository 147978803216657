import React from 'react'
import PropTypes from 'prop-types'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  PocketShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  PocketIcon,
} from 'react-share'
import { Message, SocialContainer, ShareButtonStyles } from './styles'

const ShareBar = ({ url, postTitle, isFooter = false }) => (
  <>
    {isFooter && (
      <>
        <hr />
        <Message>
          If you enjoyed this post, share it please. That way, more people will
          be able to read it. Thanks!
        </Message>
      </>
    )}
    <SocialContainer>
      <FacebookShareButton
        className={ShareButtonStyles}
        url={url}
        quote={postTitle}>
        <FacebookIcon size={40} />
      </FacebookShareButton>
      <TwitterShareButton
        className={ShareButtonStyles}
        url={url}
        title={postTitle}
        via="tuxedosnob">
        <TwitterIcon size={40} />
      </TwitterShareButton>
      <LinkedinShareButton
        className={ShareButtonStyles}
        url={url}
        title={postTitle}>
        <LinkedinIcon size={40} />
      </LinkedinShareButton>
      <PocketShareButton title={postTitle}>
        <PocketIcon size={40} />
      </PocketShareButton>
      <WhatsappShareButton title={postTitle}>
        <WhatsappIcon size={40} />
      </WhatsappShareButton>
      <TelegramShareButton title={postTitle}>
        <TelegramIcon size={40} />
      </TelegramShareButton>
    </SocialContainer>
  </>
)

ShareBar.propTypes = {
  url: PropTypes.string.isRequired,
  postTitle: PropTypes.string.isRequired,
  isFooter: PropTypes.bool,
}

ShareBar.defaultProps = {
  isFooter: false,
}

export default ShareBar
