import React from 'react' // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { graphql } from 'gatsby'
import {
  HeaderPost,
  Container,
  PostHeaderContainer,
  PostSnippetHeader,
  LargeImage,
  PostContainer,
} from '../components/Styled'
import PostIcons from '../components/PostIcons'
import Layout from '../layouts'
import ShareBar from '../components/ShareBar'
import SEO from '../components/SEO'
import MainHeader from '../components/MainHeader'

const PostTemplate = ({ data: { markdownRemark }, location: { href } }) => {
  const { frontmatter, html, fields } = markdownRemark
  const { featuredImage } = frontmatter
  const postImage =
    featuredImage &&
    featuredImage.childImageSharp.fluid &&
    featuredImage.childImageSharp.fluid.originalImg

  return (
    <>
      <SEO
        key={`seo-${frontmatter.slug}`}
        postImage={postImage}
        postData={markdownRemark}
        isBlogPost
      />
      <Layout key={`layout-${frontmatter.slug}`} title={frontmatter.title}>
        {/* <MainHeader /> */}
        <Container>
          <PostHeaderContainer>
            <HeaderPost
              dangerouslySetInnerHTML={{ __html: frontmatter.title }}
            />
            <PostSnippetHeader>
              <div>by David K</div>
              <LargeImage />
            </PostSnippetHeader>
            <PostIcons
              node={frontmatter}
              readingTime={fields && fields.readingTime.text}
              css={css`
                margin-bottom: 1.5rem;
              `}
              calendarPos={{
                position: `relative`,
                top: 1,
                marginRight: `3px`,
              }}
              clockPos={{
                position: `relative`,
                top: 2,
                marginLeft: `20px`,
                marginRight: `5px`,
              }}
            />
            <ShareBar url={href} postTitle={frontmatter.title} />
          </PostHeaderContainer>
          <PostContainer>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </PostContainer>
          <ShareBar url={href} postTitle={frontmatter.title} isFooter />
        </Container>
      </Layout>
    </>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    imageSharp: PropTypes.object,
  }),
  location: PropTypes.shape({
    href: PropTypes.string,
  }).isRequired,
}

PostTemplate.defaultProps = {
  data: {},
}

export default PostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 500)
      frontmatter {
        title
        date
        seo_title
        slug
        description
        tags
        featuredImage {
          childImageSharp {
            fluid(quality: 100) {
              originalImg
            }
          }
        }
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
