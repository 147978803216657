import styled from '@emotion/styled'
import { css } from '@emotion/core'
import mq from '../../utils/styling'

export const SocialContainer = styled.div`
  display: inline-flex;
  margin-left: 1rem;
  ${mq.large(css`
    margin-bottom: 1rem;
  `)};
`

export const Message = styled.div`
  margin-bottom: 1rem;
  font-style: italic;
`

export const ShareButtonStyles = css`
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`
